.blogtile {
    border: grey;
    text-align: left;
    background-color: #1b1c1d;
    border-radius: 5%;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;

}

.blogTileimage:hover {
    animation: pulse 500ms;
}

.blogTileimage {
    padding-bottom: 0px;
    border-bottom: darkgray;
    border-radius: 10%;
    padding-bottom: 10px;
    max-width: 100%;
    max-height: 100%;
}

.heading {
    color: white;
    font-size: large;
    font-weight: bold;
    padding-bottom: 5px;
}

.date {
    color: rgb(242, 113, 28);
    font-size: small;
    padding-bottom: 10px;
}

.description {
    color: gray;
    font-size: medium;
    padding-bottom: 30px;
}


.link {
    color: grey;
    font-size: medium;
}

.bottom {
    color: grey;
    padding-bottom: 15px;

}

.data {
    padding-top: 0px;
    padding-left: 10px;
    border-radius: 10%;
    text-align: left;

}

.row {
    padding-bottom: 0px;
}