.blogHeader{
    height: 500px;
    border-radius: 25px;
    text-align: center;
    vertical-align: middle;
}

.headerMeta{
    margin-top: 30%;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.705);
}

.headerTitle{
    opacity: 1;
    padding-top: 10%;
    -webkit-text-fill-color: darkgrey; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black; 
     font-size: 400%;
}
.author{
    display:flex;
    justify-content:center;
    vertical-align: middle;
    align-self: center;
    padding-bottom: 10px;
}
.authorName{
    display:flex;
    font-size: large;
    padding-left: 10px;
    align-self: flex-end;
}
.authorImage{
    display:flex;
    max-height: 10%;
    max-width: 10%;
    border-radius: 50%;
}

.textbody{
    background-color: rgba(83, 72, 72, 0.664);
    border-radius: 15px;
    font-size: x-large;
    color: white
}

.date{
    color:  rgb(242, 113, 28);
    font-size: xx-large;

}

.tags{
    color: darkgrey;
    font-size: large;

}